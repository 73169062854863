.navbar {
    background: linear-gradient(90deg, #dfdadabd 0%, #e6e0e0bd 100%);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 1500px;
  }

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: normal;
    margin-right: 2rem;
  }

.nav-item {
  display: flex;
  align-items: center;
  height: 60px;
}

  .nav-link {
    height: 80px;
    padding: 10px;
  }

  .nav-link-lge {
    height: 200px;
    padding: 10px;
  }

  .nav-link-large {
    height: 200px;
    padding: 10px;
    align-items: right;
  }
  .nav-links {
    color: #a494ac;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }

 .fa-bars {
    color: rgb(238, 8, 8);
  }

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
      border-radius: 2px;
      width: 60%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 8px 10px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
    
  }

  /* Social Icons */
.social-icon-link {
  color: blue;
  font-size: 30px;
}

.social-icon-link:hover {
  color: red;
  font-size: 30px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  /* margin: 20px auto 0 auto; */
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

  button {
    display: none;
  }
}
